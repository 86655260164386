.page_index{
	.index_hero{

	}

	.content > h1{
		text-align: center;
		width: 100%;
		display: block;
		font-family: @headline;
		color: black;
		border: 0;
		padding: @margin 0;
		margin: @margin 0;
		font-size: 40px;
		line-height: 40px;

		@media @mobile-min{
			font-size: 70px;
			line-height: 70px;
		}

		@media @tablet-min{
			font-size: 150px;
			line-height: 150px;			
		}
	}

	#haendler, #waisenhausplatz{

		padding-top: @height_header;
		
		border: 0;
	}
}