.index_information{

	h2{
		color: @color-brand; 
		font-family: @headline;

		// @media @mobile-min{
		// 	margin-top: 0;
		// }
	}

	p span{
		border-bottom: 1px solid;
	}
	.index_video{
		width: 100%;
		position: relative;
		margin-bottom: @margin * 2;

		&:before{
			content: '';
			padding-bottom: 56.25%;
			display: block;
		}

		iframe{
			width: 100%;
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			// margin-bottom: @margin * 2;
// 
		}
	}
}