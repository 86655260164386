.vendor_gallery{
	height: 70vh;

	.vendor_gallery_image, .vendor_gallery_video{
		height: 100%;

		iframe{
			border: 0;
			// position: absolute;
			// padding-top: ~'calc(50px + (@{margin} * 6) + 4em)';
			width: 100%;
			height: 100%;
			// height: 60vh;
			// height: 100%;
			// top: 0;
			// left: 0;
		}
	}
}