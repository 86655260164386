.page_index{
}

.index_hero{
	overflow: hidden;

  .index_hero_background{
  	position: absolute;
  	top: 0;
  	left: 0;
  	width: 100%;
		height: 100%;

		.paper;
  	
  }

	.index_hero_illustration{
		position: absolute;
		// left: 0;
		left: 0;
		right: 0;
		// top: 10vh;
		top: 0;
		// height: 100vh;
		bottom: 0;

		background-image: url(../img/homepage_illustration_07.png);
		background-size: contain;
		background-position: 50% 0;
		background-repeat: no-repeat;

		@media @mobile-max{
			left: -58vw;
			right: -14vw;
		}		

		@media @huge-min{
			background-size: cover;
		}
	}

	.index_hero_logo{
		// margin-top: @margin * 4;
		// background-image: url(../img/logo_waisenhausplatz-01.png);
		// background-size: cover;
		// background-position: top center;
		// background-repeat: no-repeat;

		position: absolute;
		top: 50vh;
		left: 50vw;
		width: 100%;
		height: 100%;
		max-width: 346px;
		max-height: 346px;
		transform: translate(-50%, -50%);

		border-radius: 50%;
		z-index: 1;

		@media @mobile-max{
			max-width: 250px;
			max-height: 250px;
		}

		// &:before{
		// 	content: '';

			
			
		// 	// .paper;
		// 	// background-attachment: fixed;

		// 	position: absolute;
		// 	top: 0;
		// 	left: 0;
		// 	height: 100%;
		// 	width: 100%;
		// 	border-radius: 50%;
		// 	z-index: 1;
		// }

		&:after{
			content: '';
			position: absolute;
			// top: 5px;
			// left: 5px;
			// height: ~'calc(100% - 10px)';
			// width: ~'calc(100% - 10px)';
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			z-index: 2;

			background-image: url(../img/logo_waisenhausplatz-03.png);
			background-size: 100%;
			background-position: top center;
			background-repeat: no-repeat;
		}
	}

	.index_hero_teaser{
		margin-top: ~'calc(100vh - 50px)';
		width: 100%;
		position: relative;
		float: left;
		padding: 0 @margin;
		// margin-bottom: 150px;
		// margin-bottom: 30vh;



		&:before, &:after{
			content: '';
			height: 4px;
			width: 40px;
			background-color: white;
			position: absolute;
			left: 50%;
			top: -50px;
		}

		&:before{
			margin: -2px 0 0 -35px;
			transform: rotate(45deg);
		}
		&:after{
			margin: -2px 0 0 -9px;
			transform: rotate(-45deg);
		}

		.index_hero_teaser_interior{
			// height: 100%;
			width: 100%;
			max-width: 800px;
			margin: 0 auto;
			position: relative;
			overflow: hidden;

			background-image: url(../img/hero_card_bg_01.jpg);
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;

			box-shadow: 0px 0px 50px 4px #444;

			padding: @margin * 4 @margin; 

			@media @mobile-min{
				padding: @margin * 8 @margin * 3; 
			}

			&:before, &:after{
				content: '';
				position: absolute;
				display: block;
				background-color: black;
				height: 3px;
				left: @margin;
				right: @margin;

				@media @mobile-min{
					left: @margin * 3;
					right: @margin * 3;
				}
			}

			&:before{
				top: @margin * 2;

				@media @mobile-min{
					top: @margin * 4;
				}
			}

			&:after{
				bottom: @margin * 2;

				@media @mobile-min{
					bottom: @margin * 4;
				}
			}


			.index_hero_text{
				position: relative;
				width: 100%;

				@media @mobile-min{
					float: left;
					width: 60%;
					padding-right: @margin;
				}


				h1{
					font-family: @headline;
					text-rendering: geometricPrecision;
					font-size: 44px;
					color: black;
					transform: translateZ(0);
					margin-top: 0;

					@media @mobile-max{
						font-size: 30px;
					}
				}
				p{
					color: black;

				}
			}

			.index_hero_text_logo{
				width: 100%;
				position: relative;
				max-width: 200px;
				margin: @margin * 2 auto @margin;
				display: block;

				@media @mobile-min{
					float: left;
					max-width: inherit;
					width: 30%;
					margin: 0 0 0 5%;

				}
				// position: absolute;
				// right: 0;
				// top: 0;
				// bottom: 0;
				// left: 60%;

				// background-image: url(../img/IWG_465.png);
				// background-size: 65% auto;
				// background-position: center;
				// background-repeat: no-repeat;


				@media @mobile-max{
					// display: none;
					// left: 0;
					// top: 60%;
					// bottom: @margin;
					// background-size: auto 90%;
				}
				@media @tiny-max{

				}
			}
		}

	}
}






