html,
body{
	position: relative;
	// color: @color-copy;
	color: black;
	font-family: @standard;
	font-weight: @light;
	font-size: @base-font-size;
	line-height: @base-line-height;	
}

.main{
	width: 100%;
	position: relative;
	min-height: 100%;
}

.content{
	position: relative;
	float: left;
	width: 100%;
	// padding:4em 0;

	// @media @mobile-max {
	// 	padding: 2em 0;
	// }	
}

.subsection{
	padding: 2em 0;

	@media @mobile-max {
		padding: 1em 0;
	}	
}

.vendor_content{
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background-color: white;
	// opacity: 1;
	// visibility: visible;
	// transform: 0;
	// transition: all .5s ease;
	z-index: 4;
	overflow: scroll;
	-webkit-overflow-scrolling: touch;

	&.closed{
		display: none;
		// opacity: 0;
		// visibility: hidden;
		// transform: translateY(@margin * 2);
	}

	.vendor_content_interior{
		// position: relative;
		// float: left;
		padding-top: @height_header;

		@media @mobile-max{
			padding-top: 65px;
		}
	}

}