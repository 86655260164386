.slider{
	margin-bottom: 0 !important;


	.slick-prev{
		left: 25px;
		z-index: 1;
	}
	.slick-next{
		right: 25px;
	}

	.slick-list{
		height: 100%;

		.slick-track{ 
			height: 100%;

			.slick-slide{
				background-size: cover;
				background-position: center;
				background-repeat: no-repeat;

			}
		}
	}
	.slick-dots{
		position: absolute;
		bottom: -50px;
		width: 100%;
		height: 50px;

		li{
			height: 100%;
			top: 0;

			&.slick-active{
				button:after{
					background-color: #888;
				}
			}
			button{
				position: relative;

				&:before{
					// height: 
					display: none;
					z-index: 2;
					font-size: 9px;
					line-height: 22px;
				}

				&:after{
					content: '';
					position: absolute;
					top: 50%;
					left: 50%;
					height: 10px;
					width: 10px;
					border-radius: 50%;
					margin: -5px 0 0 -5px;
					background-color: black;
					z-index: 1;
				}

			}
		}
	}
}