.page_under_construction{

	background-image: url(../img/paper_1080.jpg);
	background-size: cover;
	background-position: top center;
	background-repeat: no-repeat;
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;


	.under_construction_interior{
		background-image: url(../img/homepage_illustration_07.png);
		background-size: contain;
		background-position: 50% 0;
		background-repeat: no-repeat;

		position: absolute;
		left: 0;
		// right: 0;
		top: 2vh;

		height: 98vh;
		width: 100%;
		// bottom: 0;

		@media @huge-min{
			background-color: green;
			background-size: cover;
		}

		display: table;

		h1{

			display: table-cell;
			height: 100%;

			vertical-align: middle;
			text-align: center;

			span{
				display: inline-block;

				background-color: white;
				line-height: 50px;
				padding: 50px;

			}
		}
	}
}