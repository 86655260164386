.contact_hero{
	margin-bottom: @margin * 2;

	.contact_title{
		display: table;
		position: relative;
		height: 100%;
		width: 100%;
		cursor: pointer;

		.contact_title_interior{
			display: table-cell;
			vertical-align: middle;

			h1{
				font-family: @headline;
				margin: 0;
				color: white;
				text-align: center;
				

				font-size: 60px;
				line-height: 60px;

				@media @mobile-min{
					font-size: 70px;
					line-height: 70px;
				}

				@media @tablet-min{
					font-size: 150px;
					line-height: 150px;			
				}
			}
		}
	}
}


.contact_print{
	margin-top: @margin;
		
	.paper;
	// background-repeat: round;
	overflow: hidden;

	@media @mobile-max{
		background-size: cover;
		background-repeat: no-repeat;
	}

	.contact_print_interior{
		background-image: url(../img/maerit_tag_01.png);
		background-size: 75%;
		background-position: center;
		background-repeat: no-repeat;

		&:before{
			content: '';
			padding-bottom: 125.5%;
			display: block;
		}
	}
}

.contact_map{
	margin-top: 0 !important;
}