.grid, .vendor_grid{

	.vendor_grid_filter{
		padding: 0 @margin;
		margin: @margin * 2 auto @margin * 5;

		// display: flex;

		@media @mobile-max{
			margin: @margin auto @margin * 2;
			// display: block;
		}

		.vendor_grid_filter_option{
			// font-size: 50px;
			// width: 50px;
			// background-color: black;
			// color: white;
			padding: 0 @margin / 2;

			// flex: 4;
			// width: 10.5%;
			// margin-right: 1%;
			// padding: @margin;
			// display: inline-block;
			text-align: center;
			cursor: pointer;
			margin-bottom: @margin;

			// position: relative;
			// float: left;
			// width: 25%;

			@media @mobile-min{
				margin-bottom: 0;
				// flex: 1;
			}

			&:last-of-type{
				margin-right: 0;
			}

			&.selected span{
				background-color: @color-brand !important;
				color: black !important;
			}

			span{
				padding: @margin 0;
				// padding:
				display: block;
				font-family: @headline;
				text-transform: uppercase;
				background-color: black;
				color: white;
				line-height: 50px;
				font-size: 55px;

				&:hover, &:active{
					background-color: white;
					color: black;
				}
			}
		}
	}

	.vendor_grid_interior{

		margin-bottom: @margin * 6;

		.tile{
			display: block;
			position: relative;
			cursor: pointer;
			// float: left;
			text-decoration: none;
			border-bottom: 0;
			// padding-top: @margin;
			padding-left: @margin / 2;
			padding-right: @margin / 2;
			padding-bottom: @margin / 2;

			@media @mobile-min{
				padding-bottom: @margin;
			}
			// display: block;
			// padding: @margin;

			// width: 100%;

			// @media @mobile-min{
			// 	width: 33.3333%;
			// }

			// @media @tablet-min{
			// 	width: 25%;
			// }

			&:before{
				content: '';
				display: block;
				padding-bottom: 100%;
			}

			&:hover, &:focus{
				.tile_interior .tile_text{
					display: table;
				}
			}

			.tile_interior{
				position: absolute;
				top: 0;
				// top: @margin;
				// left: 0;
				left: @margin / 2;
				// height: ~'calc(100% - (@{margin} * 2))';
				// width: ~'calc(100% - (@{margin} * 2))';
				right: @margin / 2;
				// bottom: @margin;
				bottom: @margin / 2;
				// width: 100%;
				background-size: cover;
				background-position: center;
				background-repeat: no-repeat;

				text-align: center;

				@media @mobile-min{
					bottom: @margin;
				}

				.tile_text{
					height: 100%;
					width: 100%;
					position: relative;
					display: table;
					background-color: rgba(0, 0, 0, .7);
					display: none;

					.tile_text_interior{
						display: table-cell;
						vertical-align: middle;

						color: @color-brand;
						font-family: @headline;
						font-size: 40px;
						line-height: 50px;

						.tile_text_title:after{
							content: '---';
							display: block;
							position: relative;
							// bottom: 0;
						}

						p:last-of-type{
							span:after{
								margin: 0 0 0 6px;
								position: relative;
								top: -3px;
								content: '|';
							}

							span:last-of-type:after{
								content: '';
							}
						}
					}
				}
			}
		}

	}
}









